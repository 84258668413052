import axios from "axios";
import store from "@store/store";
import router from "@router/index";
import { getToken } from "./auth";
import crypto from "crypto";
import dayjs from "dayjs";

// define root api url
let APIURL =
    document.querySelector("body").getAttribute("apiurl") ||
    process.env.VUE_APP_ENDPOINT;
APIURL = APIURL + "/api/v1/";

// create an axios instance
const service = axios.create({
    baseURL: APIURL,
    timeout: 45000,
    crossDomain: true,
});

const source = axios.CancelToken.source();

// request interceptor
service.interceptors.request.use(
    (config) => {
        const token = "Bearer " + getToken("token");

        config.headers.Authorization = token;

        if (config.cancelTokenSource) {
            config.cancelToken = config.cancelTokenSource.token;
        }

        return config;
    },
    (error) => {
        // do sth with request error
        Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // error catch
        if (error.response) {
            // conditional error handler
            switch (error.response.status) {
                case 400:
                    const params = {
                        color: "red",
                        message: error.response.data.msg,
                        status: true,
                        btnName: "Close",
                        timeout: 2000,
                    };
                    store.commit("UPDATE_SNACKBAR", params);
                    break;
                case 404:
                    store.state.snackcolor = "red";
                    store.state.snackmsg = "Error 404, Page Not Found";
                    store.state.snachbar = true;
                    break;
                case 422:
                    store.state.snackcolor = "red";
                    store.state.snackmsg = error.response.msg;
                    store.state.snachbar = true;
                    break;
                case 401:
                    // authorization expired redirect to login page
                    store.state.snackcolor = "red";
                    store.state.snackbar = true;
                    store.state.snackmsg = error.response.data.msg;

                    localStorage.clear();
                    source.cancel("");
                    if (window.location.href.indexOf("signin") == -1) {
                        window.location.href = "/#/signin";
                    }
                    break;
                case 500:
                    store.state.snackcolor = "red";
                    store.state.snackbar = true;
                    store.state.snackmsg = "Error 500, Internal Server Error";
                    break;
                case 429:
                    store.state.snackcolor = "red";
                    store.state.snackbar = true;
                    store.state.snackmsg = "Too many request";
                    break;
                case 403:
                    store.state.snackcolor = "red";
                    store.state.snackbar = true;
                    store.state.snackmsg = "Permission Denied";
                    console.log("Permission Denied!");
                    router.go(-1);
                    break;
                case 600:
                    console.log("Error 600");
                    store.state.snackcolor = "red";
                    store.state.snackbar = true;
                    store.state.snackmsg = error.response.data.message;
                    break;
                case 409:
                    store.state.snackcolor = "red";
                    store.state.snackbar = true;
                    store.state.snackmsg = error.response.data.msg;
                    break;
            }
        }
        return Promise.reject(error);
    }
);
const createCancelToken = () => {
    return axios.CancelToken.source();
};

export default service;
export { createCancelToken };
