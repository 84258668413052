import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import DatetimePicker from "vuetify-datetime-picker";
import JsonExcel from "vue-json-excel";
// import ECharts from "vue-echarts";

import VueCodemirror from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "@assets/style.css";
import "@assets/style.scss";
import * as mixins from "@/components/mixins/index";

// import Toast from "vue-toastification";
// import "vue-toastification/dist/index.css";
import common from "@assets/js/common";
import { checkVersion } from "@utils/util";
import directives from "./directive";
// import jwt_decode from "jwt-decode";

import VueDraggableResizable from "vue-draggable-resizable";

// optionally import default styles
import "vue-draggable-resizable/dist/VueDraggableResizable.css";

Vue.component("vue-draggable-resizable", VueDraggableResizable);

Vue.use(common);

// you can set default global options and events when Vue.use
Vue.use(VueCodemirror, {
    options: {
        tabSize: 4,
        theme: "base16-dark",
        lineNumbers: true,
        line: true,
        mode: { name: "javascript", json: true },
    },
    events: ["scroll"],
});

Object.keys(mixins).forEach((key) => {
    Vue.mixin(mixins[key]);
});

Vue.config.productionTip = false;
Vue.use(DatetimePicker);

Object.keys(directives).forEach((key) => {
    Vue.directive(key, directives[key]);
});

Vue.component("downloadExcel", JsonExcel);

global.ENCRYPT_KEY = "bfjfunds";

router.beforeEach((to, from, next) => {
    // checkVersion();
    // 路由逻辑
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        localStorage.setItem("currentPath", to.path);

        if (localStorage.getItem("token") === null) {
            next({ path: "/signin", query: { redirect: to.fullPath } });
            localStorage.clear();
        } else {
            const bp = JSON.parse(localStorage.getItem("permission"));
            const data = bp.frontPermission;

            if (bp.allowJsonEditor) {
                // if (Object.prototype.hasOwnProperty.call(bp, "allowJsonEditor")) {
                store.state.allowJsonEditor = bp.allowJsonEditor;
            }

            // 获取broker列表
            store.state.brokerMap = bp.broker;
            if (to.meta.permission) {
                const permission = to.meta.permission;

                if (typeof permission === "string") {
                    if (data[permission]) {
                        next();
                        return;
                    } else {
                        store.state.snackcolor = "red";
                        store.state.snackmsg = "Error";
                        store.state.snackbar = "true";
                        next({ path: from.fullPath });
                        return;
                    }
                }

                if (Array.isArray(permission)) {
                    const parent = to.meta.permission[0];

                    if (
                        data[parent] &&
                        data[parent].child.includes(to.meta.permission[1])
                    ) {
                        next();
                    } else {
                        console.log(data[parent]);
                        console.log(data[parent].child);
                        console.log("Access Herer 2");
                        store.state.snackcolor = "red";
                        store.state.snackmsg = "Error";
                        store.state.snackbar = "true";
                        next({ path: from.fullPath });
                    }
                }
            }
        }
    } else {
        next();
    }
});

router.afterEach((to, from, next) => {});

// checkZoomLevel();
// const mediaQuery = diameter => "(max-width: " + diameter + "px)";
// const mediaQuery1700 = window.matchMedia(mediaQuery(1700));
// const mediaQuery1440 = window.matchMedia(mediaQuery(1440));

// mediaQuery1700.addEventListener("change", event => {
//     console.log("inner height",window.innerHeight)
//     console.log("outer height",window.outerHeight)
//     console.log(window.innerWidth);
//     if (event.matches) {
//         document.body.style.zoom = "80%"
//     } else {
//         document.body.style.zoom = "100%";
//     }
// });

// mediaQuery1440.addEventListener("change", event => {
//     console.log(window.innerWidth);
//     if (event.matches) {
//         console.log("The window is now 1440px or under");
//     } else {
//         console.log("The window is now over 1440px");
//     }
// });

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");

// function checkZoomLevel() {
//     console.log("here")
//     if (window.matchMedia("(max-width: 1280px)").matches) {
//         console.log("1")
//         document.body.style.zoom = "60%";
//     }
//     if (window.matchMedia("(max-width: 1440px)").matches) {
//         console.log("2")
//         document.body.style.zoom = "85%";
//     }
//     if (window.matchMedia("(max-width: 2170px)").matches) {
//         console.log("wtf")
//         document.body.style.zoom = "20%";
//     }
//     console.log(window.matchMedia("(width: 1280px)"))
// }
